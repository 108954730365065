<template>
  <div class="flex flex-col flex-nowrap mt-md sm:mt-lg">
    <div v-if="withArticleCount && totalItems !== null" class="flex flex-row">
      <span class="font-bold font-deco-alt text-alt-2xl mb-md"
        >{{ totalItems }}&nbsp;{{
          t('checkoutSubView.cart.articleCount')
        }}</span
      >
    </div>
    <LineItems
      :line-items="items"
      :variant="lineItemVariant"
      :voucher="voucher"
      :class="{ 'pointer-events-none': !productsInteractable }"
      :hide-strike-price-on-line-item="hideStrikePriceOnProducts"
    />
    <a
      v-if="isExtendable && lineItems.length > 1"
      class="pt-3 text-center cursor-pointer text-primary-base"
      @click="expandList()"
    >
      <span>{{ label }}</span>
      <FaIcon
        :classes="`${
          showAllProducts ? 'rotate-180 pr-2' : 'transform-none pl-2'
        }`"
        icon-class="far fa-angle-down"
      />
    </a>
  </div>
</template>
<script setup lang="ts">
import FaIcon from '~/components/fa-icon.vue';
import type { BgColors } from '@/components/page/components/lineItems/types';
import type { LineItem } from '~/server/transformers/shop/lineItemTransformer';
import LineItems from '~/components/page/components/lineItems/lineItems.vue';
import { LineItemVariant } from '~/components/page/components/lineItems/types';
import type { Voucher } from '~/server/transformers/shop/cartTransformer';

const props = defineProps({
  lineItems: {
    type: Object as PropType<LineItem[]>,
    required: true,
  },
  totalItems: {
    type: Number,
    required: false,
    default: null,
  },
  lineItemVariant: {
    type: String as PropType<LineItemVariant>,
    required: false,
    default: LineItemVariant.checkout,
  },
  isExtendable: {
    type: Boolean,
    required: false,
    default: false,
  },
  productFrameColor: {
    type: String as PropType<BgColors>,
    required: false,
    default: undefined,
  },
  productsInteractable: {
    type: Boolean,
    required: false,
    default: true,
  },
  withArticleCount: {
    type: Boolean,
    default: true,
  },
  voucher: {
    type: Object as PropType<Voucher>,
    required: false,
    default: () => null,
  },
  hideStrikePriceOnProducts: {
    type: Boolean,
    default: false,
    required: false,
  },
});

const { t } = useTrans();

const showAllProducts = ref(false);

const items = computed(() => {
  if (props.isExtendable && !showAllProducts.value) {
    return [props.lineItems[0]];
  }

  return props.lineItems;
});

const label = computed(() => {
  return showAllProducts.value ? t('list.showLess') : t('list.showMore');
});

function expandList() {
  showAllProducts.value = !showAllProducts.value;
}
</script>

<style lang="postcss" scoped>
.loading {
  @apply cursor-wait pointer-events-none grayscale opacity-50;
}
</style>
